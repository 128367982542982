<template>
  <div class="pages">
    <div class="record-info">
      <div class="info-title">
        <div class="title">
          <span class="line"></span>
          <span>浏览历史</span>
        </div>
      </div>
    </div>
    <div class="record-list" v-loading="loading">
      <div class="list" v-for="(item,index) in list" :key="index" @click="handleArticleClick(item)">
        <div class="item">
          <div class="title">
            <span>{{ item.title }}</span>
          </div>
          <div class="tag">
            <span>{{ item.name }}</span>
          </div>
        </div>
        <div class="pic">
          <img :src="item.image" alt="">
        </div>
      </div>
    </div>
    <div class="paging-info">
      <div class="pagination">
        <div class="num" :class="{active:item === page.current}" v-for="item in page.last" :key="item"
             @click="handlePageClick(item)">
          <span>{{ item }}</span>
        </div>
      </div>
      <div class="paging">
        <div class="cut" @click="handlePagePrevClick" v-show="page.current !== 1">
          <span class="left"></span>
        </div>
        <div class="cut" @click="handlePageNextClick" v-show="page.current !== page.last && list.length!==0">
          <span class="right"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BrowseRecord',
  data() {
    return {
      loading: false,
      page: {
        current: 1,
        last: 1,
      },
      list: [],
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      this.loading = true
      let res = await this.http({
        url: '/api/my/history',
        method: 'GET',
        params: {
          page: this.page.current,
        }
      })
      this.page.current = res.data.page.current
      this.page.last = res.data.page.last
      this.list = res.data.list
      this.loading = false
    },
    handlePageClick(page) {
      this.page.current = page
      this.getData()
    },
    handlePagePrevClick() {
      this.page.current--
      this.getData()
    },
    handlePageNextClick() {
      this.page.current++
      this.getData()
    },
    handleSearchClick() {
      this.page.current = 1
      this.getData()
    },
    handleArticleClick(item) {
      switch (item.type) {
        case 1:
          this.$router.push({
            path: '/hotspotDetails',
            query: {
              id: item.article_id
            }
          })
          break
        case 3:
          this.$router.push({
            path: '/lookDetails',
            query: {
              id: item.article_id
            }
          })
          break
      }
      console.log(item)
    }
  }
}
</script>

<style scoped lang="scss">
@import "BrowseRecord.scss";
</style>
